/* Specific styles for the landing page */

/* Root variables */
:root {
    --primary-color: #1aac83;
    --secondary-color: #ffffff;
    --background-color: #101820;
  }
  
  /* Landing Page Styles */
  .landing {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    color: var(--secondary-color);
    background: var(--background-color);
    overflow: hidden;
    position: relative;
    perspective: 1000px;
  }
  
  .landing .content {
    text-align: center;
    z-index: 10;
  }
  
  .landing h1 {
    font-size: 4rem;
    margin-bottom: 1rem;
  }
  
  .landing p {
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
  }
  
  .landing .links .btn {
    display: inline-block;
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 1rem;
    color: var(--primary-color);
    background: var(--secondary-color);
    border: 2px solid var(--primary-color);
    border-radius: 5px;
    text-decoration: none;
    transition: all 0.3s ease;
  }
  
  .landing .links .btn:hover {
    background: var(--primary-color);
    color: var(--secondary-color);
  }
  
  .landing .background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    perspective: 1000px;
    overflow: hidden;
  }
  
  .landing .cube {
    width: 100px;
    height: 100px;
    background: linear-gradient(45deg, var(--primary-color), var(--secondary-color));
    position: absolute;
    animation: rotate 20s infinite linear;
  }
  
  .landing .cube:nth-child(1) {
    top: 20%;
    left: 25%;
    animation-delay: -2s;
  }
  
  .landing .cube:nth-child(2) {
    top: 50%;
    left: 10%;
    animation-delay: -4s;
  }
  
  .landing .cube:nth-child(3) {
    top: 70%;
    left: 30%;
    animation-delay: -6s;
  }
  
  .landing .cube:nth-child(4) {
    top: 30%;
    left: 70%;
    animation-delay: -8s;
  }
  
  .landing .cube:nth-child(5) {
    top: 60%;
    left: 80%;
    animation-delay: -10s;
  }
  
  @keyframes rotate {
    0% {
      transform: rotateX(0deg) rotateY(0deg);
    }
    100% {
      transform: rotateX(360deg) rotateY(360deg);
    }
  }
  